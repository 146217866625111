<template>
    <Breadcrumb :title="$t('menu.serviceHotline')"></Breadcrumb>
    <div v-for="(item, index) in callCenter.datas" :key="index">
        <div style="font-weight: bold; font-size: 20px; color: black">{{ $t("callCenter." + item.key) }}</div>
        <el-main style="margin-top: -10px">
            <div v-if="item.group">
                <div v-for="(desc, index) in item.group" :key="index" style="padding-bottom:10px;">
                    <div style="font-weight: bold; margin:10px 0;"> {{ $t("callCenter." + desc.key) }} </div>
                    <div v-for="(element, index) in desc.items" :key="index"
                        style="margin-left: 15px; margin-top: 10px; margin-bottom: 5px; color: blue;"
                        @click="callPhone(element.phone)">
                        {{ element.phone }}
                    </div>
                </div>
            </div>
            <div v-else v-for="(desc, index) in item.items" :key="index">
                <div style="margin-left: 15px; margin-top: 10px; margin-bottom: 5px; color: blue;" @click="callPhone(desc)">
                    {{ desc }}
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
import callCenter from "/public/Json/callCenter.json";
import Breadcrumb from "../../../components/Breadcrumb.vue";

export default {
    name: "CallCentre",
    components: {
        Breadcrumb,
    },
    data() {
        return {
            callCenter,
            phones: {},
        };
    },
    mounted: function () {
        this.phones = callCenter.datas.find((i) => i.key === "afterSalesPhone");
        this.phones = this.phones.group.find((i) => i.key === "systemEngineer");
        this.phones.items.map((x) => {
            x.index = x.index * Math.random() * 10;
        });
        this.phones.items.sort(function (a, b) {
            return a.index - b.index;
        });
    },
    methods: {
        callPhone(phoneNumber) {
            window.location.href = "tel://" + phoneNumber;
        }
    }
};
</script>